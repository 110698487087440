import styled from "styled-components";


export const Main = styled.div`
width: 100%;
min-height: 100vh;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
background-color: white;
`;

export const Text = styled.p`
font-family: 'LM';
font-size: 20px;
font-weight: 500;
text-align: center;
color: #222831;
@media (max-width: 600px) {
    font-size: 18px;
    }
`;

export const TextRoad = styled.p`
font-family: 'LM';
font-size: 26px;
font-weight: 500;
text-align: center;
color: #222831;
width: 350px;
@media (max-width: 600px) {
    font-size: 15px;
    }
`;

export const LINK = styled.a`
font-family: 'LM';
font-size: 20px;
font-weight: 500;
text-align: center;
color: #222831;
text-decoration: none;
@media (max-width: 600px) {
    font-size: 18px;
    color: white;
    }
    :hover {
        color: #F5D835;  
    }
`;

export const HText = styled.p`
font-family: 'LM';
font-size: 26px;
font-weight: bold;
text-align: center;
color: #222831;
@media (max-width: 600px) {
    font-size: 20px;
    }
`;

export const HEadText = styled.p`
font-family: 'LM';
font-size: 36px;
font-weight: bold;
text-align: center;
color: black;
`;


export const HeaderWrapper = styled.div`
width: 100%;
height: 100px;
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: row;
background-color: white;//#b3fbff;
`;

export const Logo = styled.img`
width: 80px;
padding-left: 30px;
`;

export const IMG = styled.img`
width: 350px;
`;

export const IMGSmall = styled.img`
width: 100px;
position: relative;
    right: -30%;
`;

export const IMGSmallR = styled.img`
width: 150px;
@media (max-width: 600px) {
    width: 100px;
    }
`;

export const TeamIMG = styled.img`
width: 250px;
border-radius: 15px;
`;

export const IMGIF = styled.img`
width: 350px;
border-radius: 30px;
`;


export const Icons = styled.img`
width: 30px;
:hover {
    transform: scale(1.2);
    transition: all 0.2s;
}
`;

export const IconsWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 20px;
padding-right: 30px;
`;

export const MintContainer = styled.div`
width: 100%;
min-height: 80vh;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding-top: 25px;
gap: 30px;
background-color: #000000;
@media (max-width: 600px) {
 flex-direction: column ;
}
`;

export const Container = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding-bottom: 25px;
min-height: 75vh;
`;

export const ConnectBtn = styled.button`
width: 150px;
height: 50px;
font-family: 'WM';
font-size: 18px;
font-weight: bold;
letter-spacing: 2px;
background-color: white;//#EEEEEE;
color: #393E46;
border-radius: 15px;
border: 1px solid black;
:hover {
background-color: #393E46;
color: #EEEEEE;
transition: all 0.3s;
}
`;

export const MintBtn = styled.button`
width: 150px;
height: 50px;
font-family: 'WM';
font-size: 18px;
font-weight: bold;
letter-spacing: 2px;
background-color: white;
color: #393E46;
border-radius: 15px;
border: 1px solid black;
:hover {
background-color: #393E46;
color: #EEEEEE;
transition: all 0.3s;
}
`;

export const RNDBtn = styled.button`
width: 35px;
height: 35px;
font-family: 'WM';
font-size: 18px;
font-weight: bold;
letter-spacing: 2px;
background-color: white;
color: #393E46;
border-radius: 50px;
border: 1px solid black;
:hover {
background-color: #393E46;
color: #EEEEEE;
transition: all 0.3s;
}
`;

export const MintBTNWrapper = styled.div`
display: flex;
width: 100%;
flex-direction: row;
justify-content: space-evenly;
align-content: center;
align-items: center;
`;

export const InfoWrapper = styled.div`
    border: 1px solid black;
    border-radius: 12px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    `;

export const RNDWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 30px;
`;

export const HR = styled.hr`
width: 80%;
height: 2px;
background-color: black;
border-color: black;.
border-radius: 5px;
`;

export const LottoWrapper = styled.div`
display: flex;
width: 70%;
flex-direction: row;
justify-content: center;
align-items: center;
    padding: 10px;
    gap: 30px;
    @media (max-width: 600px) {
        flex-direction: column;
    }
    `;

export const LottoBox = styled.div`
display: flex;
width: 80%;
flex-direction: row;
justify-content: center;
align-items: center;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #D9D9D9;
    @media (max-width: 600px) {
        width: 350px;
    }
    `;

export const TeamCard = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    `;

    export const TeamWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    @media (max-width: 770px) {
        flex-direction: column;
        gap: 15px;
    }
    `;

    export const LinkWrapper = styled.div`
    display: flex;
    gap: 30px;
    @media (max-width: 600px) {
        display: none;
    }
    `;

export const LinkWrapperMobile = styled.div`
@media (max-width: 600px) {
    display: flex;
    gap: 30px;
}
@media (min-width: 605px) {
    display: none;
}
`;

export const RoadWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;

export const RoadTextWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
`;

export const ConnectWrapper = styled.div`
    display: flex;
    width: 80%;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
`;

export const MintBW = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
`;