import Footer from "./components/Footer";
import Header from "./components/Header";
import Mint from "./components/Mint";
import * as s from "./Styles";
import Fade from 'react-reveal/Fade';


function App() {
  return (
    <s.Main>
    <Header/>
    <Fade left>
    <Mint/>
    </Fade>
    <Footer/>
    </s.Main>
  );
}

export default App;
