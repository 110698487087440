/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import * as s from "../Styles";
import { ethers } from 'ethers';
import toast, { Toaster } from 'react-hot-toast';
import ABI from '../ABI.json'


function Mint() {

    const ToEther = ethers.utils.formatEther;
    const ToWei = parseInt;
    const ContractAddress = '0x8F66e05695F95194F848Fd8E7fb646E1c723eC12';
    const [Account, setAccount] = useState(null);
    const [NFTContract, setNFTContract] = useState(null);
    const [MintedSupply, setMintedSupply] = useState(0);
    const [MaxSupply, setMaxSupply] = useState(5000);
    const [Price, setPrice] = useState(0.005);
    const [DisplayPrice, setDisplayPrice] = useState(0.005);
    const [NMinted, setNMinted] = useState(0);
    const [MaxPerWallet, setMaxPerWallet] = useState(0);
    const [FreeLimit, setFreeLimit] = useState(0);
    const [FreeMinted, setFreeMinted] = useState(0);
    const [Tokens, setTokens] = useState(3);

    const connection = async() => {
        if(typeof window.ethereum !== 'undefined') {
            toast.loading("Connecting...", { style: {fontFamily: 'LM'}});
            const provider =  new ethers.providers.Web3Provider(window.ethereum);
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
              });
              const NID = await window.ethereum.request({
                method: "net_version"
               });
                const signer = provider.getSigner();
                const CT = new ethers.Contract(ContractAddress, ABI, signer);
                setNFTContract(CT);
                const price = await CT.cost();
                setPrice(ToWei(price));
                setDisplayPrice(ToEther(price));
                const TotalMinted = await CT.totalSupply();
                setMintedSupply(ToWei(TotalMinted));
                const Maxsupply = await CT.maxSupply();
                setMaxSupply(ToWei(Maxsupply));
                const NumberMinted = await CT.numberMinted(accounts[0]);
                setNMinted(ToWei(NumberMinted));
                const Maxperwallet = await CT.MaxperWallet();
                setMaxPerWallet(ToWei(Maxperwallet));
                const freelimit = await CT.FreeLimit();
                setFreeLimit(ToWei(freelimit));
                const Freeminted = await CT.AddressFreeMinted(String(accounts[0]));
                setFreeMinted(ToWei(Freeminted));
                setAccount(accounts[0]);
                setTokens(ToWei(freelimit) - ToWei(Freeminted))
                toast.dismiss();
                toast.success("Wallet Connected", {duration: 3000, style: {fontFamily: 'LM'}});

        } else {
            toast.dismiss();
            toast.error("Please Install Metamask", {duration: 3000, style: {fontFamily: 'LM'}});
        }

        window.ethereum.on("accountsChanged", (accounts) => {
            setAccount(accounts[0])
          });
          window.ethereum.on("chainChanged", () => {
            window.location.reload();
          });
  };



    const IncrementTokens = () => {
        let NewToken = Tokens + 1;
        if(FreeMinted >= FreeLimit) {
            if(NewToken > MaxPerWallet - NMinted) {
                NewToken = MaxPerWallet - NMinted;
        }
        setTokens(NewToken);
        } else {
            if(NewToken > MaxPerWallet - NMinted) {
                NewToken = MaxPerWallet - NMinted;
             }
            setTokens(NewToken);
        }

    };

    const DecrementTokens = () => {
        let NewToken = Tokens - 1;
        if(NewToken < 1) {
            NewToken = 1;
        }
        setTokens(NewToken);
    };




          const PublicMint = async() => {
            toast.loading("Minting...", {style: {fontFamily: 'LM'}});
            if (FreeMinted >= FreeLimit) {
                let price = String(Price * Tokens);
                const Mint = await NFTContract.mint(Tokens, {
                    value: price,
                    from: Account,
                    gasLimit: 155000
                  });
                 const TX = await Mint.wait()
                 .then(async(receipt) => {
                    toast.dismiss();
                  toast.success("Congrats, You Minted your NFT", {duration: 3000, style: {fontFamily: 'LM'}});
                  const NumberMinted = await NFTContract.numberMinted(Account);
                  setNMinted(ToWei(NumberMinted));
                  const Freeminted = await NFTContract.AddressFreeMinted(Account);
                  setFreeMinted(ToWei(Freeminted));
                  const TotalMinted = await NFTContract.totalSupply();
                  setMintedSupply(ToWei(TotalMinted));
                 })
                 .then("Error", (error) => {
                  console.log("err", error)
                  toast.dismiss();
                  toast.error(error.message, {duration: 3000, style: {fontFamily: 'LM'}});
                 })   
            } else {
                let price = String(Price * (Tokens - (FreeLimit - FreeMinted)));
                const Mint = await NFTContract.mint(Tokens, {
                    value: price,
                    from: Account,
                    gasLimit: 155000
                  });
                 const TX = await Mint.wait()
                 .then(async(receipt) => {
                    toast.dismiss();
                  toast.success("Congrats, You Minted your NFT", {duration: 3000, style: {fontFamily: 'LM'}});
                  const NumberMinted = await NFTContract.numberMinted(Account);
                  setNMinted(ToWei(NumberMinted));
                  const Freeminted = await NFTContract.AddressFreeMinted(Account);
                  setFreeMinted(ToWei(Freeminted));
                  const TotalMinted = await NFTContract.totalSupply();
                  setMintedSupply(ToWei(TotalMinted));
                 })
                 .then("Error", (error) => {
                  console.log("err", error)
                  toast.dismiss();
                  toast.error(error.message, {duration: 3000, style: {fontFamily: 'LM'}});
                 })   
            }

          };
        



  return (
    <s.MintContainer id='MINT'>
          <Toaster/>
          <s.ConnectWrapper>
          <s.MintBW style={{flexDirection: Account ? 'row' : 'column'}}>
        <s.IMGIF loading={'lazy'}  src='/images/RMYCgif.gif'/>
        {!Account ? (
            <>
            <s.ConnectBtn onClick={(e) => {
            e.preventDefault();
            connection();
        }}>
            Connect
        </s.ConnectBtn>
            </>
        ) : (
            <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
            <s.HText>
            {String(Account).substring(0,8)}...{String(Account).substring(34,42)}
        </s.HText>

        <s.MintBTNWrapper>
            <s.InfoWrapper>
            {FreeMinted >= FreeLimit ? (
                <>
                <s.Text>
             {String(DisplayPrice * Tokens).substring(0,5) } ETH
             </s.Text>

                </>
            ) : (
                <>
                <s.Text>
           {FreeLimit - FreeMinted} Free
            </s.Text>
                <s.Text>
             {String(DisplayPrice * (Tokens - (FreeLimit - FreeMinted))).substring(0,5) } ETH
             </s.Text>

                </>
            )}
        <s.RNDWrapper>
        <s.RNDBtn onClick={(e) => {
                e.preventDefault();
                DecrementTokens();
            }}>
                -
            </s.RNDBtn>
            <s.Text>
                {Tokens}
            </s.Text>
            <s.RNDBtn onClick={(e) => {
                e.preventDefault();
                IncrementTokens();
            }}>
                +
            </s.RNDBtn>
        </s.RNDWrapper>
        <s.Text>
            {MintedSupply} / {MaxSupply}
        </s.Text>
        {MintedSupply >= MaxSupply ? (
            <>
                <s.HText>
                    SOLD OUT
                </s.HText>
            </>
        ) : (
            <>
            <s.MintBtn onClick={(e) => {
            e.preventDefault();
            PublicMint();
        }}>
             Mint
        </s.MintBtn>
        </>
        )}
        </s.InfoWrapper>
        </s.MintBTNWrapper>
        </div>
            </>
            )}
        </s.MintBW>


        </s.ConnectWrapper>
    </s.MintContainer>
  )
}

export default Mint